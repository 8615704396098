import React from 'react'
import ReactMarkdown from 'react-markdown'

const Markdown = ({ children }) => (
    <ReactMarkdown
        components={{
            p: (props) => <p className="prose mb-8" {...props} />,
            ul: (props) => <ul className="prose list-disc pl-8 mb-8" {...props} />,
            h6: (props) => <h6 className="prose mb-2 text-blue font-medium" {...props} />,
        }}
    >
        {children.replace(/\\\\"/g, '\\"').replace(/\\\\n/g, '\\n').replace(/\\n/g, '\n')}
    </ReactMarkdown>
)

export default Markdown
