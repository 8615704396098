import React from 'react'
import SbEditable from 'storyblok-react'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import TourPage from './TourPage'

const TourPageWithState = ({
    data,
    supplierContent,
    resolveRelations,
}) => {
    const { content } = useStoryblokBridge(data.pageStory, resolveRelations)
    return (
        <SbEditable content={content}>
            <TourPage content={content} supplierContent={supplierContent} />
        </SbEditable>
    )
}

export default TourPageWithState
