import React from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import * as constants from '@connections/constants'
import {
    sectionSpacingVariant,
    sectionContainerVariant,
    sectionBackgroundVariant,
} from '../../util/variants'
import { variant } from '../../util/index'
import DynamicColumn from './DynamicColumn'

const {
    ALIGN_TOP,
    ALIGN_CENTER,
    ALIGN_BOTTOM,
    COLUMN_LEFT,
    COLUMN_RIGHT,
} = constants
const MOBILE_ORIENTATION_DEFAULT = 'default'
const MOBILE_ORIENTATION_REVERSE = 'reverse'

const ColumnsSection = ({
    content,
    supplierContent,
}) => {
    const {
        align,
        spacing,
        columns,
        anchorId,
        container,
        background,
        mobileOrientation = MOBILE_ORIENTATION_DEFAULT,
    } = content

    return (
        <SbEditable content={content}>
            <section
                id={anchorId || undefined}
                data-testid="ColumnsSection"
                className={clsx(
                    'overflow-hidden',
                    sectionBackgroundVariant(background),
                    sectionSpacingVariant({ spacing }),
                )}
            >
                <div
                    className={clsx(
                        'flex',
                        variant(mobileOrientation, {
                            [MOBILE_ORIENTATION_DEFAULT]: 'flex-wrap',
                            [MOBILE_ORIENTATION_REVERSE]: 'flex-wrap-reverse',
                        }),
                        variant(align, {
                            [ALIGN_TOP]: 'items-start',
                            [ALIGN_CENTER]: 'items-center',
                            [ALIGN_BOTTOM]: 'items-end',
                        }),
                        sectionContainerVariant(container),
                    )}
                >
                    {columns.map((column, index) => (
                        <DynamicColumn
                            content={column}
                            key={column._uid}
                            background={background}
                            supplierContent={supplierContent}
                            position={index % 2 === 0 ? COLUMN_LEFT : COLUMN_RIGHT}
                            className={clsx(
                                variant(align, {
                                    [ALIGN_TOP]: 'items-start',
                                    [ALIGN_CENTER]: 'items-center',
                                    [ALIGN_BOTTOM]: 'items-end',
                                }),
                            )}
                        />
                    ))}
                </div>
            </section>
        </SbEditable>
    )
}

export default ColumnsSection
