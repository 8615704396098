import React from 'react'
import Page from '../../layout/Page'
import PageMain from '../../layout/PageMain'
import StickyHeader from '../../layout/StickyHeader'
import HeaderWithContent from '../../layout/HeaderWithContent'
import FooterWithContent from '../../layout/FooterWithContent'
import Heading from '../../content/Heading'
import DynamicSection from '../../content/DynamicSection'
import QuickSearchSectionWithState from '../../content/QuickSearchSectionWithState'
import TourPageSubHeader from './TourPageSubHeader'

function getHeadingContent(content, nezasaTripId, supplierContent) {
    let { name, title, price, description } = content

    if (supplierContent) {
        if (nezasaTripId && _.isEmpty(supplierContent)) {
            title = `No trip found for id ${nezasaTripId}`
        } else {
            name = supplierContent.name
            title = supplierContent.title
            price = supplierContent.price
            description = supplierContent.description
        }
    }

    return {
        name,
        title,
        price,
        description,
    }
}

const TourPage = ({ content, supplierContent }) => {
    const {
        headImage,
        headVideo,
        body = [],
        nezasaTripId,
        headingOptions = [{}],
    } = content
    const { name, title, price, description } = getHeadingContent(
        content,
        nezasaTripId,
        supplierContent
    )
    const [firstSection] = body
    const hasHeadingSection = firstSection?.component === 'HeadingSection'

    return (
        <Page data-testid="TourPage">
            <StickyHeader>
                <HeaderWithContent />
                <TourPageSubHeader name={name} />
            </StickyHeader>
            <PageMain>
                {hasHeadingSection ? null : (
                    <Heading
                        price={price}
                        title={title}
                        image={headImage}
                        video={headVideo}
                        description={description}
                        {...headingOptions[0]}
                    />
                )}
                {nezasaTripId ? (
                    <QuickSearchSectionWithState
                        openTab="trip"
                        tabs={['trip']}
                        container="default"
                        title="Book this tour"
                        anchorId="tour-page-qsm"
                        nezasaTripId={nezasaTripId}
                    />
                ) : null}
                {body &&
                    body.map((sectionContent) => (
                        <DynamicSection
                            pageContent={content}
                            content={sectionContent}
                            key={sectionContent._uid}
                            supplierContent={supplierContent}
                        />
                    ))}
            </PageMain>
            <FooterWithContent />
        </Page>
    )
}

export default TourPage
