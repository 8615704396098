import React, { useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import { getSimpleLocale } from '@connections/utils'
import { render } from 'storyblok-rich-text-react-renderer'
import { getUniqueObject } from '../../util'
import { getBounds, getPointsFromDayItems } from '../../util/map'
import usePageProps from '../hooks/usePageProps'
import usePathnameLocale from '../hooks/usePathnameLocale'
import DayByDayMapSection from './DayByDayMapSection'

const mapCoordinates = (point) => point.latitude + point.longitude

const getMapData = (dayItems) => {
    let key = 0
    const dayItemsMapped = dayItems.map((dayItem, index) => {
        const { points = [] } = dayItem
        const previousCoordinates =
            index !== 0 ? dayItems[index - 1].points.map(mapCoordinates) : []
        const currentCoordinates = dayItems[index].points.map(mapCoordinates)
        const hasTheSameCoordinatesAsPrevious =
            previousCoordinates.filter((point) =>
                currentCoordinates.includes(point)
            ).length > 0
        if (!hasTheSameCoordinatesAsPrevious) {
            key += 1
        }
        const mappedPoints = points.map((point) => ({
            ...point,
            title: point.title || dayItem.title,
            name: point.name || dayItem.name,
            key,
        }))

        const hotelHasDescription = dayItem.hotels?.some(
            (hotel) => hotel.description
        )
        const description = render(dayItem.description)
        const hasMoreInfo = description !== null || hotelHasDescription

        return {
            ...dayItem,
            hasMoreInfo,
            key,
            points: mappedPoints,
        }
    }, [])
    const bounds = getBounds(dayItems)
    const points = getPointsFromDayItems(dayItemsMapped)
    const markers = getUniqueObject(
        points,
        (item) => item.key + item.latitude + item.longitude
    )

    return {
        key,
        bounds,
        markers,
        dayItemsMapped,
    }
}

const DayByDayMapSectionWithState = ({
    content,
    pageContent,
    supplierContent,
}) => {
    const {
        size,
        title,
        spacing,
        container,
        initialZoom,
        fillWithSupplierContent,
    } = content
    const dayItems =
        fillWithSupplierContent && supplierContent.dayItems
            ? supplierContent.dayItems
            : pageContent.dayItems

    const mapRef = useRef()
    const dayItemRefs = useRef({})
    const locale = usePathnameLocale()
    const { isPreview } = usePageProps()
    const [zoom, setZoom] = useState(initialZoom)
    const [isCentered, setIsCentered] = useState(true)
    const [selectedKey, setSelectedKey] = useState(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const [hoveredMarker, setHoveredMarker] = useState(null)
    const { key, bounds, markers, dayItemsMapped } = getMapData(dayItems)
    const fitBoundsOptions = {
        padding: 50,
        maxZoom: initialZoom || 22,
    }
    const initialViewState = {
        bounds,
        fitBoundsOptions,
    }

    const handleLoad = () => {
        setZoom(mapRef.current.getZoom())
        // Only FR is supported and English is default
        const language = getSimpleLocale(locale)
        if (language === 'fr') {
            const map = mapRef.current.getMap()
            map.getStyle().layers.forEach((layer) => {
                if (layer.layout && layer.layout['text-field']) {
                    map.setLayoutProperty(layer.id, 'text-field', [
                        'coalesce',
                        ['get', `name_${language}`],
                        ['get', 'name'],
                    ])
                }
            })
        }
        setIsMapLoaded(true)
    }

    const handleMouseEnterMarker = (marker) => {
        setHoveredMarker(marker)
    }

    const handleMouseLeaveMarker = () => {
        setHoveredMarker(null)
    }

    const handleClickMarker = (e, marker) => {
        if (!isPreview) {
            e.stopPropagation()
            setSelectedKey(marker.key)
            dayItemRefs.current[marker.key].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    const handleClickMoreInfo = (info) => {
        setModalContent(info)
    }

    const handleCloseModal = () => {
        setModalContent(null)
    }

    const handleCenter = () => {
        mapRef.current.fitBounds(bounds, fitBoundsOptions)
        setIsCentered(true)
    }

    const handleMoveStart = () => {
        setIsCentered(false)
        setSelectedKey(null)
    }

    const handleZoomEnd = (e) => setZoom(e.viewState.zoom)

    const handleClickDay = (newKey) => {
        setSelectedKey(newKey)
    }

    const handleClickMap = () => {
        setSelectedKey(null)
    }

    return (
        <SbEditable content={content}>
            <DayByDayMapSection
                zoom={zoom}
                size={size}
                title={title}
                lastKey={key}
                mapRef={mapRef}
                markers={markers}
                spacing={spacing}
                onLoad={handleLoad}
                container={container}
                isCentered={isCentered}
                onCenter={handleCenter}
                onZoomEnd={handleZoomEnd}
                dayItemRefs={dayItemRefs}
                selectedKey={selectedKey}
                dayItems={dayItemsMapped}
                isMapLoaded={isMapLoaded}
                onClickDay={handleClickDay}
                onClickMap={handleClickMap}
                modalContent={modalContent}
                onMoveStart={handleMoveStart}
                hoveredMarker={hoveredMarker}
                onCloseModal={handleCloseModal}
                onClickMarker={handleClickMarker}
                initialViewState={initialViewState}
                onClickMoreInfo={handleClickMoreInfo}
                onMouseEnterMarker={handleMouseEnterMarker}
                onMouseLeaveMarker={handleMouseLeaveMarker}
            />
        </SbEditable>
    )
}

export default DayByDayMapSectionWithState
