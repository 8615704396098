import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import CollapsableSection from './CollapsableSection'

const CollapsableSectionWithState = ({
    content,
    supplierContent,
}) => {
    const [expanded, setExpanded] = useState(null)
    const collapsableItems = content.fillWithSupplierContent && !!supplierContent?.infoSections
        ? supplierContent.infoSections
        : content.collapsableItems

    const handleExpand = (index) => {
        setExpanded(index === expanded ? false : index)
        if (expanded && index !== expanded && index !== 0) {
            const currentItem = collapsableItems[expanded]
            const currentElement = document.getElementById(currentItem._uid)
            const nextItem = collapsableItems[index]
            const nextElement = document.getElementById(nextItem._uid)
            // The 100px account for the button height and vertical margin
            window.scrollTo({ top: nextElement.offsetTop - currentElement.clientHeight - 100 })
        }
    }

    return (
        <SbEditable content={content}>
            <CollapsableSection
                {...content}
                expanded={expanded}
                onExpand={handleExpand}
                collapsableItems={collapsableItems}
            />
        </SbEditable>
    )
}

export default CollapsableSectionWithState
