import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import Icon from '../utils/Icon'
import CustomText from '../utils/CustomText'

const Excursion = (excursion) => {
    const {
        path,
        _uid,
        title,
        summary,
        isSupplierContent,
    } = excursion

    return (
        <SbEditable content={excursion} key={_uid}>
            <div className="border-b border-gray-100 last:border-0">
                <h3 className="font-medium mb-1">
                    {title}
                </h3>
                <div className="mb-2 text-sm">
                    {isSupplierContent
                        ? <div dangerouslySetInnerHTML={{ __html: summary }} />
                        : summary}
                </div>
                {path ? (
                    <Link href={path} target="_blank">
                        <button type="button" className="text-blue-bright py-3">
                            <Trans id="More info" />
                        </button>
                    </Link>
                ) : null}
            </div>
        </SbEditable>
    )
}

const Hotel = (hotel) => {
    const {
        name,
        _uid,
    } = hotel

    return (
        <SbEditable content={hotel} key={_uid}>
            <div className="flex items-center gap-2">
                <Icon name="bed" color="blue-bright" />
                <span className="font-medium text-sm uppercase">
                    {name}
                </span>
            </div>
        </SbEditable>
    )
}

const DayByDayMapSectionSidebar = ({
    title,
    lastKey,
    dayItems,
    className,
    onClickDay,
    dayItemRefs,
    selectedKey,
    onClickMoreInfo,
}) => (
    <div
        className={clsx(
            'bg-white',
            className,
        )}
    >
        {title && (
            <div className="bg-blue p-6 text-center">
                <CustomText
                    as="h3"
                    className="text-white text-3xl font-medium mt-2"
                    customTextClassName="font-accent text-[2.5rem]"
                    customText={title}
                />
            </div>
        )}
        {dayItems.map((dayItem, index) => {
            const {
                key,
                name,
                hotels,
                excursions,
                hasMoreInfo,
                title: dayTitle,
                shortDescription,
                descriptionsAreHtml,
            } = dayItem
            const isFirstOrLast = index === 0 || index === dayItems.length - 1 || key === lastKey
            const mapRef = (ref) => {
                const refNotAdded = typeof dayItemRefs.current[key] === 'undefined'
                if (refNotAdded) {
                    // eslint-disable-next-line no-param-reassign
                    dayItemRefs.current[key] = ref
                }
            }

            return (
                <SbEditable content={dayItem} key={dayItem._uid}>
                    <div
                        ref={mapRef}
                        className={clsx(
                            'px-6 py-5 flex flex-col gap-4 border-gray-100 border-b',
                            selectedKey === key && 'bg-gray-50'
                        )}
                    >
                        <button
                            type="button"
                            onClick={() => onClickDay(key)}
                            className="flex flex-col gap-4 text-left"
                        >
                            <div className="flex justify-between items-center gap-2 w-full">
                                <div>
                                    <h2 className="text-xs font-medium uppercase">
                                        {dayTitle}
                                    </h2>
                                    <h2 className="font-medium text-lg">
                                        {name}
                                    </h2>
                                </div>
                                <div
                                    className={clsx(
                                        'w-5 h-5 rounded-full flex items-center justify-center text-xs text-white font-bold flex-shrink-0',
                                        isFirstOrLast ? 'bg-blue-bright' : 'bg-primary',
                                    )}
                                >
                                    {key}
                                </div>
                            </div>
                            {(() => {
                                if (shortDescription) {
                                    if (descriptionsAreHtml) {
                                        return <div dangerouslySetInnerHTML={{ __html: shortDescription }} />
                                    }
                                    return (
                                        <p>
                                            {shortDescription}
                                        </p>
                                    )
                                }
                                return null
                            })()}
                        </button>
                        <div className="mb-4 flex flex-col gap-4">
                            {hotels.length > 0 && (
                                <div className="flex flex-col gap-2">
                                    {hotels.map(Hotel)}
                                </div>
                            )}
                            {hasMoreInfo && (
                                <button
                                    type="button"
                                    className="text-blue-bright mr-auto"
                                    onClick={() => onClickMoreInfo(dayItem)}
                                >
                                    <Trans id="More info" />
                                </button>
                            )}
                        </div>
                        {excursions.length > 0 && (
                            <div className="border border-blue-bright rounded-md py-2 px-4">
                                <div className="flex items-center mb-2">
                                    <span className="text-blue-bright flex items-center gap-2">
                                        <Icon name="mountains" />
                                        <span className="text-sm font-medium">
                                            <Trans id="Excursion tip" />
                                        </span>
                                    </span>
                                    <span className="ml-auto text-xs font-medium">
                                        <Trans id="Optional" />
                                    </span>
                                </div>
                                <div className="flex flex-col gap-4">
                                    {excursions.map(Excursion)}
                                </div>
                            </div>
                        )}
                    </div>
                </SbEditable>
            )
        })}
    </div>
)

export default DayByDayMapSectionSidebar
