import React from 'react'
import Link from 'next/link'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'

const DayByDayMapSectionModalHotels = ({
    hotels,
}) => {
    if (hotels.length === 0) {
        return null
    }

    return (
        <div className="flex flex-col gap-4 mt-9 empty:hidden">
            {hotels.map((hotel) => {
                const {
                    _uid,
                    name,
                    fullSlug,
                    description,
                } = hotel

                if (!description && !fullSlug) {
                    return null
                }

                return (
                    <div
                        key={_uid}
                        className="bg-gray-50 p-6 rounded-md flex flex-col gap-2"
                    >
                        <div className="flex items-center gap-2 justify-between w-full">
                            <div className="flex items-center gap-2">
                                <Icon name="bed" color="blue-bright" />
                                <span className="font-medium text-sm uppercase">
                                    {name}
                                </span>
                            </div>
                            {fullSlug && (
                                <Link href={fullSlug} className="text-blue-bright" target="_blank">
                                    <Trans id="More info" />
                                </Link>
                            )}
                        </div>
                        {description && (
                            <p className="whitespace-pre-line">
                                {description}
                            </p>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default DayByDayMapSectionModalHotels
