import React from 'react'
import { Trans } from '@lingui/react'
import { render } from 'storyblok-rich-text-react-renderer'
import Icon from '../utils/Icon'
import Image from '../utils/Image'
import Modal from '../utils/Modal'
import RichTextField from './RichTextField'
import DayByDayMapSectionModalHotels from './DayByDayMapSectionModalHotels'

const DayByDayMapSectionModal = ({
    onClose,
    content,
}) => {
    const {
        name,
        image,
        title,
        hotels,
        description,
        shortDescription,
        descriptionsAreHtml,
    } = content

    return (
        <Modal className="max-w-2xl overflow-hidden" onClose={onClose}>
            <div className="h-60 relative flex items-center justify-center text-white bg-black">
                <button
                    onClick={onClose}
                    type="button"
                    className="absolute top-6 right-9 flex items-center z-[2] gap-2"
                >
                    <Trans id="Close" />
                    <Icon name="cross" size="smaller" />
                </button>
                <div className="relative z-[2] text-center mt-8">
                    <h2 className="uppercase font-medium text-xs">
                        {title}
                    </h2>
                    <h3 className="font-accent text-7xl">
                        {name}
                    </h3>
                </div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black z-1" />
                {image?.src && (
                    <>
                        <div className="md:hidden">
                            <Image
                                fill
                                maxWidth={672}
                                src={image.src}
                                className="object-cover"
                            />
                        </div>
                        <div className="hidden md:block">
                            <Image
                                fill
                                maxWidth={672}
                                maxHeight={240}
                                src={image.src}
                                className="object-cover"
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="px-8 py-6">
                {(() => {
                    if (descriptionsAreHtml) {
                        return <div dangerouslySetInnerHTML={{ __html: description || shortDescription }} />
                    }

                    const hasDescription = render(description) !== null
                    if (hasDescription) {
                        return <RichTextField content={description} className="last:prose-p:mb-0" />
                    }

                    return (
                        <div>
                            {shortDescription}
                        </div>
                    )
                })()}
                <DayByDayMapSectionModalHotels hotels={hotels} />
            </div>
        </Modal>
    )
}

export default DayByDayMapSectionModal
